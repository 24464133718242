<template>
  <section class="hero has-background-light is-fullheight" style="margin-top: -3.25rem;">
    <div class="hero-head">
      <Navbar />
    </div>

    <div class="hero-body section">
      <div class="container">
        <div class="columns px-4">
          <div class="column is-6">
        
            <div class="buttons">
              <a class="navbar-item ml-0" @click="$router.back()">
                Back
              </a>
            </div>

            <h1 class="title">
              Having Trouble Logging In?
            </h1>

            <p class="mb-5">
              No worries! Just enter your email below and we'll promptly send you a link to create a new password.
            </p>

            <template v-if="!showSuccess">
              <FormField
                label="Email"
                type="email"
                v-model="email"
              />

              <article v-if="error" class="message is-danger">
                <div class="message-body">
                  {{ error }}
                </div>
              </article>

              <button
                :class="`button is-black mt-5 ${isPending && 'is-active'}`"
                :disabled="!email"
                @click="resetPassword()"
              >
                Send Reset Link
              </button>
            </template>

            <template v-if="showSuccess">
              <h3 class="subtitle">
                Please check your inbox.
              </h3>

              <router-link :to="{ name: 'LogIn' }" class="button is-black">
                Log in
              </router-link>
            </template>
        
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'
import Navbar from '@/components/Navbar1.vue'
import FormField from '@/components/Forms/FormField.vue'

export default {
  components: { Navbar, FormField },
  setup() {
    const { error, sendPasswordResetEmail, isPending } = useLogin()
    const email = ref('')
    const showSuccess = ref(false)

    const resetPassword = async () => {
      await sendPasswordResetEmail(email.value)
      if (error.value) return
      showSuccess.value = true
    }

    return {
      email,
      resetPassword,
      error,
      isPending,
      showSuccess
    }
  }
}
</script>